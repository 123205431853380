.gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5vh;
}
.gallery-item {
  width: calc(100% / 3 - 10px);
  flex-wrap: wrap;
  height: auto;
  background: rgb(243, 243, 243);
  margin-bottom: 15px;
  text-decoration: none;
  color: #000000;
}

.gallery-item h3 {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}

.gallery-item p {
  font-size: 15px;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.gallery-item-image {
  width: 100%;
  overflow: hidden;
}

.gallery-item-image img {
  height: 250px;
}

@media (max-width: 1155px) {
  .gallery-item {
    width: calc(100% / 2 - 10px);
  }
}

@media (max-width: 770px) {
  .gallery-item {
    width: 100%;
  }
  .gallery-item-image img {
    width: 100%;
    height: auto;
  }
}
