.button {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  padding: 15px 25px;
  border: 4px solid #fff;
  border-radius: 70px 28px 70px 28px;
  transition: 300ms;
}

.button:hover {
  background: #fff;
  color: #000;
  border-radius: 28px;
}
