header {
  background: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 12vh;
  position: fixed;
  transition: 300ms;
  z-index: 9999;
}

.site-scrolled {
  background: rgba(0, 0, 0, 0.9);
}

.nav-container {
  max-width: 1280px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 15px;
  box-sizing: border-box;
}

.nav-container-logo {
  height: 100%;
  max-height: 60px;
  max-width: 300px;
}

.nav-container-logo img {
  height: 100%;
}

nav {
  position: relative;
}

.nav-container-link {
  cursor: pointer;
  margin-right: 20px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  transition: 300ms;
}

.nav-container-link:hover {
  color: #b8995c;
}

.toggler {
  display: none;
}

.nav-container-link:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  header {
    background: rgba(0, 0, 0, 0.85);
  }

  .toggler {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 35px;
    height: 30px;
  }

  .toggler span {
    width: 100%;
    height: 4px;
    background-color: #fff;
    border-radius: 5px;
    transition: 300ms;
  }

  .toggler-active span:nth-child(1) {
    transform: rotate(-45deg) translateY(7px) translateX(-7px);
  }

  .toggler-active span:nth-child(2) {
    opacity: 0;
  }

  .toggler-active span:nth-child(3) {
    transform: rotate(45deg) translateY(-7px) translateX(-7px);
  }

  nav {
    position: absolute;
    top: 12vh;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 88vh;
    background: rgba(8, 8, 8, 0.85);
    transition: 300ms;
    transform: translate(-100vw);
  }
  .nav-container-link {
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 20px;
  }
  .nav-active {
    transform: translate(0vw);
  }
}
