.puppies-section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.puppies-section-margin {
  padding-top: 15vh;
}

.puppies-section-container {
  max-width: 1280px;
  height: auto;
  margin: 0 auto;
  padding: 5vh 15px;
  box-sizing: border-box;
}

.puppies-section h2 {
  font-size: 25px;
  transform: translateY(3vh);
  color: #000000;
  font-weight: 500;
  margin-bottom: 0;
}
