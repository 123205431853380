.content-section {
  width: 100%;
  height: auto;
  padding: 5vh 5%;
  box-sizing: border-box;
}

.grey-section {
  background: #fafafa;
}

.white-section {
  background: #ffffff;
}

.content-section-margin {
  padding-top: 17vh;
}

.content-section-container {
  max-width: 1280px;
  margin: 0 auto;
}

.content-section-container h2 {
  font-size: 20px;
  color: #8b754a;
  font-weight: 500;
}

.content-section-container p {
  font-size: 15px;
  font-weight: 400;
}

.content-section-container ul {
  list-style-type: none;
  padding-left: 0px;
}

.contact-list {
  max-width: 1240px;
  margin: 5vh auto 0 auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.contact-list li {
  margin-bottom: 3vh;
}

.contact-list li b {
  font-weight: 500;
}

.contact-list li a {
  text-decoration: none;
  color: #000;
}
