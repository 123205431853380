.dogs-section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.dogs-section-margin {
  padding-top: 12vh;
}

.dogs-section-container {
  max-width: 1280px;
  height: auto;
  margin: 0 auto;
  padding: 5vh 15px;
  box-sizing: border-box;
}

.dogs-section-container h2 {
  font-size: 20px;
  color: #8b754a;
  font-weight: 500;
}
