.hero-section {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/hero.jpg");
  background-size: cover;
  background-position: 60%;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section .button {
  transform: translateY(5vh);
}

.booked-dog-section {
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-image: url("../../assets/booked-dog.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.booked-dog-section h2 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.organisation-section {
  width: 100%;
  height: auto;
  padding: 7vh 5% 5vh 5%;
  box-sizing: border-box;
}

.organisation-section-container {
  max-width: 1280px;
  margin: 0 auto;
}

.organisation-section-container h2 {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}

.organisation-section-container p {
  font-size: 15px;
  font-weight: 400;
}
