footer {
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-image: url("../../assets/footer.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.footer-container {
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  box-sizing: border-box;
}

.footer-container-box {
  width: calc(100% / 4 - 30px);
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
}

.footer-container-box h2 {
  color: #b8995c;
  font-size: 20px;
  font-weight: 500;
}

.footer-container-box p {
  color: #fff;
  font-size: 13px;
}

.footer-container-box ul {
  color: #fff;
  padding-left: 20px;
  font-size: 13px;
}

.footer-container-box a {
  color: #fff;
  font-size: 13px;
}

@media (max-width: 768px) {
  .footer-container-box {
    width: calc(100% / 2 - 30px);
  }
}

@media (max-width: 420px) {
  .footer-container-box {
    width: 100%;
  }
}
